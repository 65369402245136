import { Box, Button, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { BasicButton } from '../../button'
import navigation from '../../header/navigation.json';

export default function HeaderMenuModal({ close, socials }) {
    return (
        <div className="sidebar-modal">
            <ul className='navigation'>
                {navigation.map((nav, i) => (
                    <li key={i}>
                        <BasicButton 
                            to={nav.to}
                            className='sidebar-modal'
                            component={Link}
                            title={nav.title}
                            onClick={close}
                        />
                    </li>
                ))}
            </ul>
            <Box display='flex' justifyContent='center' gap={1}>
                {socials.map((social) => (
                    <IconButton
                        key={social.id}
                        aria-label={social.name}
                        LinkComponent='a'
                        target='_blank'
                        rel="noreferrer noopener"
                        href={social.url}
                        sx={{
                            aspectRatio: 1/1,
                            '&:hover': {
                                background: social.colors.bg,
                                color: social.colors.text,
                            }
                        }}
                    >
                        {social.icon}
                    </IconButton>
                ))}
            </Box>
        </div>
    )
}
