import React from 'react';
import './Footer.scss';
import Name from '../Name';

export default function Footer() {
  return (
    <div className='footer'>
        <footer>
          <p>Copyright @2025, andrescortes Todos los derechos reservados.</p>
        </footer>
    </div>
  )
}
